/* ************************************************** HEADER TABS ******************************************************* */

.ticket-tabs__header {
    display: flex;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    /* Firefox */
}

.ticket-tabs__header::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Edge */
}

.ticket-tabs__header__tabs {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    padding: 0 12px;
}

.ticket-tabs__header__tabs__item {
    text-align: center;
    padding: 10px 0;
    margin-right: 40px;
    position: relative;
    cursor: pointer;
    display: flex;
    border-radius: 8px;
    padding: 11px 8px;
    align-items: center;
    font-weight: 500;
    color: #9E9E9E;
    white-space: nowrap;
}

.ticket-tabs__header__tabs__item.active {
    color: #212121;
}

.ticket-tabs__header__tabs__item__count {
    /* display: flex; */
    min-width: 24px;
    height: 16px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background-color: #BCBCBC;
    color: #fff;
    font-weight: 500;
    margin-left: 5px;
    padding: 0 4px;
}

.ticket-tabs__header__tabs__item.active .ticket-tabs__header__tabs__item__count {
    background-color: #2979ff;
}

.ticket-tabs__header__tabs__item:hover {
    background-color: #f5f5f5;
}

.ticket-tabs__header__tabs__item:before {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    display: block;
    right: 0;
    bottom: 0px;
    background-color: #2979ff;
    transition: width .2s ease;
}

.ticket-tabs__header__tabs__item.active::before {
    width: 100%;
    left: 0;
}

/* ******************************************************************** BODY TABS ***************************************************** */
.ticket-tabs__body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 160px);
}

.spinner {
    width: 13px;
    text-align: center;
}

.spinner>div {
    width: 4px;
    height: 4px;
    margin-bottom: 3px;
    background-color: #fff;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .ticket-tabs__header__tabs {
        padding: 0 8px;
    }

    .ticket-tabs__header__tabs__item {
        margin-right: 20px;
        padding: 10px 6px;
        font-size: 14px;
    }

    .ticket-tabs__body {
        height: calc(100vh - 140px);
    }
}

@media (max-width: 480px) {
    .ticket-tabs__header__tabs {
        padding: 0 4px;
    }

    .ticket-tabs__header__tabs__item {
        margin-right: 12px;
        padding: 8px 6px;
        font-size: 13px;
    }

    .ticket-tabs__header__tabs__item__count {
        min-width: 20px;
        height: 14px;
        font-size: 11px;
    }

    .ticket-tabs__body {
        height: calc(100vh - 130px);
    }
}