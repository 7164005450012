.custom-radio-group .ant-radio-button-wrapper-checked {
    background-color: #ffba00 !important;
    border-color: #ffba00 !important;
    color: #212121 !important;
}

.custom-radio-group .ant-radio-button-wrapper {
    border-radius: 8px !important;
    margin-right: 8px;
    border: 1px solid #e5e7eb !important;
}

.custom-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
}

.custom-radio-group .ant-radio-button-wrapper:hover {
    color: #212121 !important;
}

.custom-radio-group .ant-radio-button-wrapper-checked:hover {
    border-color: #ffba00 !important;
    background-color: #ffba00 !important;
}

/* Remove the default focus styles */
.custom-radio-group .ant-radio-button-wrapper:focus-within {
    box-shadow: none !important;
}

/* Add spacing between buttons */
.custom-radio-group {
    display: flex !important;
    gap: 8px !important;
}

/* Make sure buttons don't stick together */
.custom-radio-group .ant-radio-button-wrapper:not(:first-child) {
    margin-left: 0 !important;
}