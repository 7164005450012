.card-container {
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid #e5e7eb;
}

.card-container:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

/* Add responsive styles */
@media (max-width: 768px) {
  .card-container {
    margin: 0 1rem;
  }

  .grid-cols-4 {
    grid-template-columns: 1fr;
  }

  .col-span-3 {
    grid-column: span 1;
  }

  .col-span-1 {
    display: none;
  }
}

.card-wrapper-product {
  position: relative;
  border-radius: 12px;
}

.card-wrapper-product .status {
  z-index: 9;
  top: 2px;
  left: 2px;
  padding: 4px;
  position: absolute;
  min-height: 20px;
  background-color: #2979ff;
  color: white;
  width: 60px;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
}

.card-wrapper-product .sold {
  z-index: 9;
  top: 2px;
  left: 2px;
  padding: 4px;
  position: absolute;
  min-height: 20px;
  background-color: #6c757d;
  color: white;
  min-width: 40px;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
}

.card-wrapper-product .stop_sale {
  z-index: 9;
  top: 2px;
  left: 2px;
  padding: 4px;
  position: absolute;
  min-height: 20px;
  background-color: black;
  color: white;
  min-width: 40px;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
}

.card-wrapper-product .sale_urgent {
  z-index: 9;
  top: 2px;
  left: 2px;
  padding: 4px;
  position: absolute;
  min-height: 20px;
  background-color: #b91a1a;
  color: white;
  min-width: 40px;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
}

.card-product-title {
  font-family: "Lexend Medium", Roboto, Arial !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500 !important;
  letter-spacing: -.2px;
  color: #2C2C2C;
  text-transform: uppercase;
}